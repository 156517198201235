import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { Layout, ZyppysExploreComponent } from "../components";
import { fetchExplorePosts, fetchVideoCategories } from "../state";
import { AgenciesSection as ExplorePostsSection } from "../style";
import { Form } from "../style/globalStyles";
import { BallBeat } from "react-pure-loaders";

function ZyppysExplore({
  posts = [],
  fetchExplorePosts,
  // fetchMoreExplorePosts,
  videocategories = [],
  fetchVideoCategories,
  loadingposts,
  errorposts,
}) {
  //   if (location.state === null || undefined) {
  //     // console.log(location.state)
  //   }
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.auth.user);
  const loginBool = Boolean(loginData.portalUserId);
  // const [prevCategory, setPrevCategory] = useState(undefined);
  // const [explorePosts, setExplorePosts] = useState([]);
  const iniPostsFetchInput = {
    videoCategory: null,
    pageNumber: 1,
    pageLimit: 5,
  };
  const [currentInput, setCurrentInput] = useState(iniPostsFetchInput);
  var videoCategoriesOptions = videocategories.map((type) => (
    <option value={type.id} key={type.id} label={type.lookupDescription}>
      {" "}
      {type.lookupDescription}{" "}
    </option>
  ));

  const fetchPostsByCategory = (e) => {
    // setExplorePosts([]);

    const catId = Number(e.target.value);
    setCurrentInput({
      ...iniPostsFetchInput,
      videoCategory: catId,
    });
    fetchExplorePosts({
      ...iniPostsFetchInput,
      videoCategory: catId,
      // pageNumber: 1,
    });
  };
  // const concatPosts = (newPosts = []) => {
  //   console.log("concat---->>>>>>>>>>>");
  //   if (newPosts === [] || newPosts === null) {
  //     return;
  //   }
  // currentInput.videoCategory === prevCategory ||
  // (currentInput.videoCategory !== null && prevCategory === 101)
  // ?
  // setExplorePosts([...explorePosts, ...newPosts]);
  // : currentInput.videoCategory !== prevCategory &&
  // currentInput.pageNumber === 1
  // ? setExplorePosts(newPosts)
  // : null;
  // setPrevCategory(currentInput.videoCategory);
  // };
  useEffect(() => {
    if (!loginBool) {
      navigate("/login");
    }
    // if (posts.length === 0) {
    //   setExplorePosts([]);
    // }
    // if (explorePosts.length === 0) {
    //   // console.log("fetchExplorePosts being called", posts);
    //   // fetchExplorePosts(currentInput, concatPosts);
    //   setExplorePosts(posts);
    // }

    // console.log(explorePosts.length, posts.length);
    // if (currentInput.videoCategory !== prevCategory) setExplorePosts([]);
    // currentInput.videoCategory === prevCategory &&
    //   currentInput.pageNumber > 1,
    // currentInput,
    // prevCategory

    if (videocategories.length === 0) {
      //   console.log(posts);
      fetchVideoCategories();
    }
  }, []);
  return (
    <div>
      <Layout>
        <ExplorePostsSection>
          {/* <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
            <p
              onClick={() => {
                typeof history !== "undefined" && history.go(-1);
              }}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              Swing Users
            </p>
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.9rem",
                marginBottom: ".9rem",
                marginLeft: "1rem",
                marginRight: "1rem",
              }}
            >
              {" "}
              &#8827;
            </p>
            <p
              style={{
                // cursor: "pointer",
                fontSize: "0.7rem",
                marginBottom: "1rem",
              }}
            >
              User ID: {location.state ? location.state.data.id : " "}
            </p>
          </div> */}

          {/* <br /> */}

          <h1>Explore Posts</h1>
          <br />

          <select
            style={{
              // float: "right",
              // marginRight: "4rem",
              fontSize: "0.9rem",
              height: "2rem",
              border: "deepskyblue solid 1px",
              // backgroundColor: "white",
              marginTop: "1rem",
            }}
            value={currentInput.videoCategory}
            onChange={(e) => {
              fetchPostsByCategory(e);
            }}
          >
            <option>Select a Category</option>
            <option value={0} key={0} label={"All"}>
              {" "}
              All{" "}
            </option>

            {videoCategoriesOptions}
          </select>
          <br />

          {loadingposts ? (
            <>
              <br />

              <h4>
                Loading posts{" "}
                <span>
                  <BallBeat color={"#123abc"} loading={loadingposts} />
                </span>{" "}
              </h4>
            </>
          ) : errorposts ? (
            <>
              <h4>ERROR ⚠️ FETCHING Posts</h4>
              <br />
              <p style={{ color: "grey" }}>{JSON.stringify(errorposts)}</p>
              <br />
              <br />
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  width: "100%",
                  justifyContent: "flex-start",
                }}
              >
                {posts.length !== 0 ? (
                  <>
                    {/* {explorePosts.map((post, index) => ( */}
                    <ZyppysExploreComponent
                      posts={posts}
                      currentInput={currentInput}
                    />
                    {/* ))} */}
                  </>
                ) : (
                  <>
                    <h4
                      style={{
                        margin: "2rem 0",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      No posts available for this category
                    </h4>
                  </>
                )}
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1.5rem",
                }}
              >
               
                <p> Total posts: {explorePosts.length}</p>
                <p
                  onClick={() => {
                    // setCurrentPage(currentPage+1)

                    setCurrentInput({
                      ...currentInput,
                      pageNumber: currentInput.pageNumber + 1,
                    });
                    fetchMoreExplorePosts(
                      {
                        ...currentInput,
                        pageNumber: currentInput.pageNumber + 1,
                      },
                      concatPosts
                    );
                  }}
                  style={{
                    marginLeft: "1rem",
                    padding: "0.5rem",
                    fontSize: "1rem",
                    borderRadius: "0.3rem",
                    backgroundColor: "gray",
                    color: "white",
                    cursor: "pointer",
                  }}
                >
                  More Posts
                </p>
              </div> */}
            </>
          )}
        </ExplorePostsSection>
      </Layout>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    posts: state.zyppysextended.posts,
    loadingposts: state.zyppysextended.loadingposts,
    errorposts: state.zyppysextended.errorposts,
    videocategories: state.zyppysextended.videocategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchExplorePosts: (input, func) =>
      dispatch(fetchExplorePosts(input, func)),

    fetchVideoCategories: () => dispatch(fetchVideoCategories()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ZyppysExplore);
